import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import PortableText from "@sanity/block-content-to-react"

const BlogPage = ({ data }) => {
  const post = data.sanityPost._rawBody
  return (
    <Layout>
      <SEO title="Blog" />
      <h1>Blog</h1>
      <div>
        <PortableText blocks={post} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PostQuery {
    sanityPost {
      _rawBody
    }
  }
`

export default BlogPage
